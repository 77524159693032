<template>
    <b-overlay :show="dataLoaded">
        <b-card title="Seznam nepremčnin">
            <b-row>
                <b-col cols="12" sm="6" class="text-center text-sm-left">
                    <b-button variant="primary" @click="$router.push({name: 'add-real-estate'})" v-if="$hasPermissions($permissions.EditRealEstate)">Dodaj nepremičnino</b-button>
                </b-col>
            </b-row>
            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je oglas po brisanju še vedno viden, ponovno naložite stran.</b-alert>
            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/es/real_estates" :fromElastic="true">
                <template #cell(main_photo)="row">
                    <b-img v-if="row.item.main_photo !== ''" fluid  :src="'/api/management/v1/image/thumbnail_' + row.item.main_photo" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>
                <template #cell(size)="row">
                    <span>{{row.item.size}} m<sup>2</sup></span>
                </template>
                <template #cell(date_posted)="row">
                    {{moment(row.item.date_posted).format('DD.MM.YYYY')}}
                </template>
                <template #cell(active)="row">
                    <b-badge v-if="row.item.active" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditRealEstate)">
                    <div class="button-grid">
                        <!-- <b-button variant="primary" :href="'/nepremicnine/' + row.item.id" target="_blank"><fa icon="newspaper"/></b-button> -->
                        <b-button variant="warning" @click="$router.push({name: 'edit-real-estate', params:{ real_estate_id: row.item.id }})"><fa icon="edit"/></b-button>
                        <b-button @click="deleteRealEstate(row.item.id)" variant="danger"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>
<script>
    import Table from '@/views/Components/Table'
    import {BOverlay, BCard, BRow, BCol, BButton, BImg, BBadge, BAlert} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BImg,
            BBadge,
            BAlert
        },
        data() {
            return {
                dataLoaded: false,
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'title', label: 'Ulica', sortable: true, class: 'text-center' },
                    { key: 'location', label: 'Lokacija', sortable: true, class: 'text-center' },
                    { key: 'size', label: 'Velikost', sortable: true, class: 'text-center' },
                    { key: 'build', label: 'Zgrajeno', sortable: true, class: 'text-center' },
                    { key: 'floor', label: 'Etažnost', sortable: true, class: 'text-center' },
                    { key: 'date_posted', label: 'Datum', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'active', label: 'Aktivno', sortable: true, class: 'text-center', type: 'bool' }
                ],
                hasBeenDeleted: false

            }
        },
        methods: {
            deleteRealEstate(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati nepremičnino.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.dataLoaded = true
                        const loadPromise = this.$http.delete(`/api/management/v1/real_estate/${id}`)
                        loadPromise.then(function() {
                            thisIns.$printSuccess('Nepremičnina je izbrisana')

                            thisIns.hasBeenDeleted = true

                            setTimeout(() => {
                                thisIns.hasBeenDeleted = false
                            }, 10_000)
                          
                            setTimeout(() => {
                                thisIns.$refs.table.search()
                            }, 1000)
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.dataLoaded = false
                        })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditRealEstate)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>
<style scoped>
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}
</style>